import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
`;

const Label = styled.h3`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 16px;
  font-weight: 600;
  margin: 0;
`;

const Content = styled.div`
  color: #000000;
  font-weight: 300;
  display: flex;
  flex-direction: column;
  white-space: pre-wrap;
`;

const ProjectDetailItem = ({
  label, children,
}) => (
  <Container>
    <Label>{label}</Label>
    <Content>{children}</Content>
  </Container>
);

ProjectDetailItem.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default ProjectDetailItem;
