import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.primary};

  margin-top: 10px;
  @media only screen and (min-width: 450px) {
    margin-top: 20px;
  }
  @media only screen and (min-width: 690px) {
    margin-top: 30px;
  }
`;

// Title font size is 1.5x subtitle font size
const Title = styled.h1`
  font-weight: 600;
  margin: 0;

  font-size: 30px;
  @media only screen and (min-width: 650px) {
    font-size: 36px;
  }
`;

const Subtitle = styled.h2`
  font-weight: 600;
  opacity: 0.7;
  margin: 0;

  font-size: 20px;
  @media only screen and (min-width: 750px) {
    font-size: 24px;
  }
`;

const ProjectTitle = ({ title, subtitle }) => (
  <Container>
    <Title>{title}</Title>
    <Subtitle>{subtitle}</Subtitle>
  </Container>
);

ProjectTitle.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default ProjectTitle;
