import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: #000000;
  margin-top: 40px;
`;

const Label = styled.h3`
  font-size: 16px;
  font-weight: 600;
  margin: 0;
`;

const Content = styled.div`
  font-size: 16px;
  font-weight: 300;
  display: flex;
  flex-direction: column;
  white-space: pre-wrap;
`;

const ProjectDescription = ({ children }) => (
  <Container>
    <Label>Project Description</Label>
    <Content>
      <ReactMarkdown>
        {children}
      </ReactMarkdown>
    </Content>
  </Container>
);

ProjectDescription.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ProjectDescription;
