import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ProjectDetailItem from './project-detail-item';

const Container = styled.div`
  display: grid;
  grid-gap: 20px;
  max-width: 600px;
  margin-bottom: 20px;

  margin-top: 40px;
  grid-template-columns: 1fr;
  @media only screen and (min-width: 690px) {
    margin-top: 60px;
    grid-template-columns: auto auto;
  }
`;

const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const ProjectDetails = ({
  projectDate, hardware, software, projectLevel, client,
}) => (
  <Container>
    {projectDate && (
      <ProjectDetailItem label="Project Date">
        {projectDate}
      </ProjectDetailItem>
    )}
    {hardware && (
      <ProjectDetailItem label="Hardware">
        <List>
          {hardware.map((item) => <li key={item}>{item}</li>)}
        </List>
      </ProjectDetailItem>
    )}
    {software && (
      <ProjectDetailItem label="Software">
        <List>
          {software.map((item) => <li key={item}>{item}</li>)}
        </List>
      </ProjectDetailItem>
    )}
    {projectLevel && (
      <ProjectDetailItem label="Project Level">
        {projectLevel}
      </ProjectDetailItem>
    )}
    {client && (
      <ProjectDetailItem label="Client">
        {client}
      </ProjectDetailItem>
    )}
  </Container>
);

ProjectDetails.defaultProps = {
  projectDate: null,
  hardware: null,
  software: null,
  projectLevel: null,
  client: null,
};

ProjectDetails.propTypes = {
  projectDate: PropTypes.string,
  software: PropTypes.arrayOf(PropTypes.string),
  hardware: PropTypes.arrayOf(PropTypes.string),
  projectLevel: PropTypes.string,
  client: PropTypes.string,
};

export default ProjectDetails;
