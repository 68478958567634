import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Img from 'gatsby-image';
import Layout from '../../../containers/layout';
import PageTransition from '../../common/page-transition';
import PaddedPage from '../../common/padded-page';
import ProjectTitle from './project-title';
import ProjectDetails from './project-details';
import ProjectDescription from './project-description';

const HeaderImage = styled(({ imagePath, ...props }) => <Img {...props} />)`
  width: 100%;
  height: 40vh;
`;

const ProjectPageTemplate = ({ pathContext }) => {
  const {
    title,
    subtitle,
    mainImage,
    projectDate,
    hardware,
    software,
    projectLevel,
    client,
    description,
    downloadableFile,
    galleryImages,
  } = pathContext;

  return (
    <Layout>
      <PageTransition>
        <HeaderImage fluid={mainImage.childImageSharp.fluid} />
        <PaddedPage>
          <ProjectTitle title={title} subtitle={subtitle} />
          <ProjectDetails
            projectDate={projectDate}
            hardware={hardware}
            software={software}
            projectLevel={projectLevel}
            client={client}
          />
          <ProjectDescription>
            {description}
          </ProjectDescription>
        </PaddedPage>
      </PageTransition>
    </Layout>
  );
};

ProjectPageTemplate.propTypes = {
  pathContext: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    mainImage: PropTypes.objectOf(PropTypes.object).isRequired,
    sortDate: PropTypes.string,
    projectDate: PropTypes.string,
    hardware: PropTypes.arrayOf(PropTypes.string),
    software: PropTypes.arrayOf(PropTypes.string),
    projectLevel: PropTypes.string,
    client: PropTypes.string,
    description: PropTypes.string,
    downloadableFile: PropTypes.string,
    galleryImages: PropTypes.arrayOf(PropTypes.shape(
      {
        image: PropTypes.objectOf(PropTypes.object),
        caption: PropTypes.string,
      },
    )),
  }).isRequired,
};

export default ProjectPageTemplate;
